import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { GiLightningHelix } from "react-icons/gi";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from "react-router-dom";

const pages = ['Home', 'Skills', 'Projects', 'Contact'];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [isScrolled, setIsScrolled] = React.useState(false);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <AppBar position="sticky">
            <Container maxWidth='100%' sx={{
            top: 0,
            backgroundColor: isScrolled ? '#14181f' : '#11151c',
            transition: 'background-color 0.3s ease',
            zIndex: 1000,
        }}>
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        component="a"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
                            fontFamily: 'san serif',
                            fontSize: "22px",
                            fontWeight: 'bold',
                            letterSpacing: '.2rem',
                            color: '#ffd483b5',
                            textDecoration: 'none',
                            padding: '10px',
                        }}
                    >
                        Aviv Shapira
                        <GiLightningHelix style={{ fontSize: '22px', marginTop: '8.5px', color:'#ae4040' }} />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                            >
                            {pages.map((page) => (
                                <ScrollLink style={{ textDecoration: 'none', color: 'black' }}
                                key={page}
                                to={page.toLowerCase()}
                                spy={true}
                                smooth={true}
                                offset={page.toLowerCase() === 'contact' ? 750 : -50}
                                duration={500}
                                >
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                </ScrollLink>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
                            flexGrow: 1,
                            fontFamily: 'san serif',
                            fontWeight: 550,
                            fontSize: "20px",
                            margin: 'auto',
                            letterSpacing: '.1rem',
                            color: '#ffd483b5',
                            textDecoration: 'none',
                        }}
                        >
                        Aviv Shapira
                        <GiLightningHelix style={{ fontSize: '22px', marginTop: '4px', color:'#ae4040' }} />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <ScrollLink style={{ textDecoration: 'none', color: 'black' }}
                            key={page}
                            to={page.toLowerCase()}
                            spy={true}
                            smooth={true}
                            offset={page.toLowerCase() === 'contact' ? 700 : -100}
                            duration={500}
                            >
                                <Button
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{
                                        my: 2, color: '#d5d5d5', display: 'block', margin: "7px", fontFamily: "Oswald, sans-serif", fontSize: "18px",  paddingTop: '12px'

                                        // boxShadow: page.route === path ? '0px 0px 8px 4px #613789' : ''
                                    }}
                                >
                                    {page}
                                </Button>
                            </ScrollLink>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0, padding: '15px' }}>
                        <Tooltip title="Aviv Shapira">
                            <IconButton sx={{
                                p: 0,
                                display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }
                            }}>
                                <Link to={'https://www.linkedin.com/in/avivshapira/'} target='_blank'>
                                <Avatar
                                    alt="My LinkedIn"
                                    src="../assets/projectpfp.png"
                                    sx={{
                                        width: 50,
                                        height: 50,
                                    }}
                                />
                                </Link>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;