import React from 'react'
import './Footer.css'
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import { GiLightningHelix } from 'react-icons/gi';

export default function Footer() {
    return (
        <div className='footer-container'>

            <div className='footer-content'>
                <p style={{ marginTop: '20px' }}>Created By: <b>Aviv Shapira All rights reserved Copyright ©</b></p>
                <>
                    <p className='icon-p' style={{ textAlign: 'center', color: '#c4cba7ba' }}>
                    Aviv Shapira
                        <GiLightningHelix style={{ fontSize: '34px', marginTop: '7px' }} />
                    </p>
                </>
                <div className='footer-icons'>
                    <Link to={'https://www.linkedin.com/in/avivshapira'} target="_blank"><FaLinkedin size={48} style={{ color: '#5894f1', marginTop:'3.8px' }} /></Link>
                    <Link to={'https://github.com/YoungShap'} target="_blank" ><GitHubIcon style={{ color: 'white', fontSize: '48px', borderRadius: '50%', marginTop:'1.5px' }} /></Link>
                </div>

            </div>






        </div>
    )
}
