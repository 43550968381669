import { useContext, useRef, useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';
import { FaWhatsapp } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import './Contact.css';
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { GeneralContext } from "../App";

export default function ContactForm() {
    const { snackbar } = useContext(GeneralContext);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_oltxwyi', 'template_o9k7gmf', form.current, {
            publicKey: '2HiX5IwlCMEZgVKSK',
        })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setMessage("");
                    setEmail("");
                    setName("");
                    snackbar('Email Sent, Thank You!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };


    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                width: '80%',
                height: "95vh",
                padding: '20px'
            }}
        >
            <Box sx={{ maxWidth: 900, p: 4, mt: '1500px', height: 'auto', width: '100%', color: '#ff983f', backgroundColor: '#15181d', borderRadius: '10px', boxShadow: '5px 5px 10px 6px rgba(0, 0, 0, 0.274)' }}>
                <Typography variant="h4" align="center" mb={2}>
                    Contact
                </Typography>
                <form ref={form} onSubmit={sendEmail} className="contact-form">
                    <TextField style={{ border: 'none', borderRadius: '5px', backgroundColor: '#303238', padding: "0px" }}
                        name="user_name"
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                        required
                        sx={{ '& .MuiInputBase-input': { color: '#c4c1c1' } }}
                    />
                    <TextField style={{ border: 'none', borderRadius: '5px', backgroundColor: '#303238', padding: "0px" }}
                        name="user_email"
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        required
                        type="email"
                        sx={{ '& .MuiInputBase-input': { color: '#c4c1c1' } }}
                    />
                    <TextField style={{ border: 'none', borderRadius: '5px', backgroundColor: '#303238', padding: "0px" }}
                        name="message"
                        fullWidth
                        label="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        margin="normal"
                        required
                        multiline
                        rows={4}
                        sx={{ '& .MuiInputBase-input': { color: '#c4c1c1' } }}
                    />
                    <div className="form-icons">
                        <Button className="my-form-btn" style={{ backgroundColor: '#0000007d', marginRight: "12px", border: '2px solid #fdc65ec7' }} variant="contained" type="submit" sx={{ mt: 2, borderRadius: '8px' }}>
                            Submit
                        </Button>
                        <div className="form-icons2">
                            <Link to={'https://github.com/YoungShap'} target="_blank"  ><GitHubIcon style={{ color: '#fdc65ec7', fontSize: '41px', backgroundColor: '#15181d', borderRadius: '50%', padding: "0", marginTop: "18px" }} /></Link>
                            <Link to={'https://www.linkedin.com/in/avivshapira'} target="_blank"><FaLinkedin style={{ color: '#fdc65ec7', fontSize: '40px', padding: "1px", marginTop: "20px", borderRadius: '8px' }} /></Link>
                            <Link to={"https://wa.me/972546676271?text="} target="_blank"><FaWhatsapp style={{ color: 'black', fontSize: '36px', backgroundColor: '#fdc65ec7', padding: "1.5px", marginTop: "18px", borderRadius: '20px' }} /></Link>
                        </div>
                    </div>
                </form>
            </Box>
        </Box>
    );
}