import React, { createContext, useCallback, useState } from 'react';
import './App.css';
import Banner from './components/Banner';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';
import { Element } from 'react-scroll';
import Snackbar from './components/Snackbar';

export const GeneralContext = createContext();

function App() {
  const [snackbarText, setSnackbarText] = useState('');

  const snackbar = useCallback((text) => {
    setSnackbarText(text);
    setTimeout(() => setSnackbarText(''), 3 * 1000);
  }, []);

  return (
    <GeneralContext.Provider value={{snackbar}}>
    <div className="App">
      <Navbar />
      <Element name="home">
        <Banner />
      </Element>
      <Element name="skills">
        <Skills />
      </Element>
      <Element name="projects">
        <Projects />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
      {snackbarText && <Snackbar text={snackbarText} />}
      <Footer />
    </div>
    </GeneralContext.Provider>
  );
}

export default App;
