import React from 'react';
import '../components/Banner.css';
import { Button } from '@mui/material';
import { useTypingEffect } from '../hooks/typing-effect';
// import { Link as ScrollLink } from 'react-scroll';

export default function Banner() {
    const text2 = useTypingEffect("And i'm a Full-Stack Developer", 80);

    const handleDownload = () => {
        const fileUrl = '/AvivShapiraCV.pdf'; // Replace with the actual path or URL of your CV file
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'AvivShapiraCV.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div className='banner-container1' id='home'>
            <div className='links-about'>
                <h1>Hey, I'm Aviv Shapira</h1>
                <p><b>{text2}</b></p>
                <p>
                    Experienced in Building dynamic Web solutions, Committed to delivering top-notch projects with a mix of technical expertise and creative design, simplifying complexities through coding.</p>
                <div className='buttons-about'>
                    <Button onClick={handleDownload} style={{ height: '50px', width: '200px' }} variant="contained">Download CV</Button>
                </div>
            </div>
            <div className='photo'></div>
        </div>
    )
}
