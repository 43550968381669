import * as React from 'react';
import './Projects.css'
import GitHubIcon from '@mui/icons-material/GitHub';
import { TbWorldWww } from 'react-icons/tb';
import { Link } from "react-router-dom";

export default function Projects() {
    return (
        <div className='projects-container3'>
            <h1>PROJECTS</h1>
            <div className='one-card-frame'>
                <div className='one-card'>
                    <div className='image-container'>
                        <img src={'/assets/devquest.png'} alt={'hh'} />
                    </div>
                    <h1>DevQuest</h1>
                    <div className='card-content'>
                        <p><b>Description: </b> DevQuest is a platform made for Developers and anyone eager to enhance their programming skills. it offers a diverse range of web projects (with github links) spanning various categories of web technologies, learning sources and more!</p>
                        <p><b>Tehcnologies:</b> React, Node.Js, MongoDB, Express, Bcrypt, JWT, Multer, Nodemailer,JOI, MUI, Moment, Morgan, React-Router-Dom.</p>
                        <Link to={'https://github.com/YoungShap/devquest'} target='_blank'><GitHubIcon style={{ color: 'white', fontSize: '35px', backgroundColor: 'black', borderRadius: '50%', padding: "0", marginTop: "30px" }} /></Link>
                        <Link to={'https://devquest-z350.onrender.com/'}><TbWorldWww style={{ color: '#358ed1', fontSize: '36px', borderRadius: '50%', padding: "0", marginTop: "30px", marginLeft: '4px', marginBottom: '-1px' }} /></Link>
                    </div>
                </div>
                <div className='one-card'>
                    <div className='image-container'>
                        <img src={'/assets/portSC.png'} alt={'hh'} />
                    </div>
                    <h1>Cardify+</h1>
                    <div className='card-content'>
                        <p><b>Description: </b> Cardify+ is a web-based application that simplifies the process of designing and managing business cards for various users. The project focuses on enhancing user engagement and interaction through different user levels: normal, business, and admin users.</p>
                        <p><b>Tehcnologies:</b> React, RESTful API's, React Icons, JOI, MUI, Moment, React-Dom, React-Router-Dom, Styled-Components. </p>
                        <Link to={'https://github.com/YoungShap/Cardify'} target='_blank'><GitHubIcon style={{ color: 'white', fontSize: '34px', backgroundColor: 'black', borderRadius: '50%', padding: "0", marginTop: "30px" }} /></Link>
                        <Link to={'https://cardify-naat.onrender.com/'} target='_blank'><TbWorldWww style={{ color: '#358ed1', fontSize: '36px', borderRadius: '50%', padding: "0", marginTop: "30px", marginLeft: '4px', marginBottom: '-1px' }} /></Link>
                    </div>
                </div>
                <div className='one-card'>
                    <div className='image-container'>
                        <img src={'/assets/weWise.png'} alt={'hh'} />
                    </div>
                    <h1>WeWise</h1>
                    <div className='card-content'>
                        <p><b>Description: </b>Contributing to a Real Project in a Startup, Developing & maintaining the Frontend side of WeWise’s Products (2) while working with a Full-Stack team in an Agile work environment.
                        </p>
                        <p><b>Tehcnologies:</b> JavaScript, React, Next.js, Redux, Figma, Jira, Tailwind CSS.
                        </p>
                        <Link to={'https://wewise.co.il/'} target='_blank' > <TbWorldWww style={{ color: '#358ed1', fontSize: '36px', borderRadius: '50%', padding: "0", marginTop: "70px", marginBottom: '-1px' }} /></Link>
                    </div>
                </div>
                <div className='one-card'>
                    <div className='image-container'>
                        <img src={'/assets/pet.png'} alt={'petClinic'} />
                    </div>
                    <h1>Pet Clinic</h1>
                    <div className='card-content'>
                        <p><b>Description: </b>A Simple, Single Page App serving as a pet clinic dashboard for managing clinic operations efficiently. It enables seamless CRUD actions for overseeing clinic clients and their data, offering a user-friendly interface for streamlined management</p>
                        <p><b>Tehcnologies:</b> Next.js, TypeScript, Tailwind CSS, MUI, CSS, MongoDB(Atlas).</p>
                        <Link to={'https://github.com/YoungShap/my-pet-pal'} target='_blank'><GitHubIcon style={{ color: 'white', fontSize: '34px', backgroundColor: 'black', borderRadius: '50%', padding: "0", marginTop: "52px" }} /></Link>
                        <Link to={'https://my-pet-pal-9cee0ac97410.herokuapp.com/'} target='_blank' > <TbWorldWww style={{ color: '#358ed1', fontSize: '36px', borderRadius: '50%', padding: "0", marginTop: "52px", marginBottom: '-1px' }} /></Link>
                    </div>
                </div>
                <div className='one-card'>
                    <div className='image-container'>
                        <img src={'/assets/pim.png'} alt={'hh'} />
                    </div>
                    <h1>Pim Massage</h1>
                    <div className='card-content'>
                        <p><b>Description: </b>Simple Landing Page for a Private Client designed by customer request, added relevant information and content about the business and deployed with a custom domain.</p>
                        <p><b>Tehcnologies:</b> HTML5, CSS, SCSS, Bootstrap</p>
                        <Link to={'https://github.com/pim-massage/pim-website/tree/main'} target='_blank'><GitHubIcon style={{ color: 'white', fontSize: '34px', backgroundColor: 'black', borderRadius: '50%', padding: "0", marginTop: "90px" }} /></Link>
                        <Link to={'https://www.pimmassage.com/'} target='_blank' > <TbWorldWww style={{ color: '#358ed1', fontSize: '36px', borderRadius: '50%', padding: "0", marginTop: "90px", marginBottom: '-1px' }} /></Link>
                    </div>
                </div>
                <div className='one-card'>
                    <div className='image-container'>
                        <img src={'/assets/snake.png'} alt={'hh'} />
                    </div>
                    <h1>Snake Game</h1>
                    <div className='card-content'>
                        <p><b>Description: </b>Snake Game built with JavaScript as a part of the Full-Stack program that I successfully completed in HackerU. (100+)</p>
                        <p><b>Tehcnologies:</b> JavaScript, HTML5, CSS, SCSS</p>
                        <Link to={'https://github.com/YoungShap/HackerU-Projects-Beginning/tree/master/JS-TS-Projects/project1'} target='_blank'><GitHubIcon style={{ color: 'white', fontSize: '34px', backgroundColor: 'black', borderRadius: '50%', padding: "0", marginTop: "90px" }} /></Link>
                        <Link to={'https://youngshap.github.io/HackerU-Projects-Beginning/JS-TS-Projects/project1/index.html'} target='_blank' > <TbWorldWww style={{ color: '#358ed1', fontSize: '36px', borderRadius: '50%', padding: "0", marginTop: "110px", marginBottom: '-1px' }} /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
